import TraitCard from "./TraitCard";
import { NFTCollection, CollectionItem } from "../../types/Collection";
import NFTDetailsItem from "./NFTDetailsItem";
const NftDetails: React.FC<{
  item: CollectionItem;
  collection: NFTCollection;
  isLoading: boolean;
}> = ({ item, collection, isLoading }) => {
  function toShort(str: string): string {
    str = str.toString();
    if (str.length < 17) {
      return str;
    } else {
      return str.slice(0, 7) + "..." + str.slice(str.length - 6);
    }
  }

  return (
    <div className={`w-full flex-col rounded-lg border border-grey-medium`}>
      <NFTDetailsItem
        isLoading={isLoading}
        isOpen={true}
        containerStyles="p-4"
        title="Description"
      >
        <p className="text-[14px] text-left text-grey-dark font-normal">
          {item?.item_data?.description}
        </p>
      </NFTDetailsItem>
      <NFTDetailsItem
        isLoading={isLoading}
        isOpen={true}
        containerStyles="flex flex-wrap gap-4 justify-left p-4"
        title="Traits"
      >
        {Object.keys(item.item_data.traits).map((trait, index) => {
          return (
            <div className="w-[30%]" key={index}>
              {collection.stats.traits_stats[trait].items_with_trait ? (
                <TraitCard
                  name={toShort(item.item_data.traits[trait])}
                  category={toShort(trait)}
                  rareness={`${collection.stats.traits_stats[trait].items_with_trait[item.item_data.traits[trait]]} items with this trait`}
                  rare_stat={
                    "Rarity: " +
                    (
                      (100 *
                        collection.stats.traits_stats[trait].items_with_trait[
                          item.item_data.traits[trait]
                        ]) /
                      collection.stats.items
                    ).toFixed(2)
                  }
                />
              ) : (
                <TraitCard
                  name={toShort(item.item_data.traits[trait])}
                  category={toShort(trait)}
                  rareness={`-`}
                  rare_stat={"Rarity: N/A"}
                />
              )}
            </div>
          );
        })}
      </NFTDetailsItem>
    </div>
  );
};

export default NftDetails;
